import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Container from "../components/container"
import Seperator from "../components/seperator"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Button from "../components/button"
import Title from "../components/title"
import styles from "./Index.module.scss"

export default function Home() {
  return (
    <>
      <SEO title="AFFA Eğitim" />
      <Layout>
        <Hero />
        <Container>
          <section className={styles.section}>
            <header>
              <Title headingLevel="h2">EĞİTİMLER</Title>
            </header>
            <Seperator />
            <Tabs>
              <TabList>
                <Tab>İş Güvenliği Uzmanlığı Eğitimleri</Tab>
                <Tab>Eğiticinin Eğitimi</Tab>
                <Tab>Mesleki Yeterlilik Sınav ve Özel Eğitimleri</Tab>
                <Tab>Yangınla Mücadele ve Yangın Söndürme Eğitimi</Tab>
                <Tab>ISO 17024 Dokümantasyonu ve Danışmanlık</Tab>
              </TabList>

              <TabPanel>
                Hızla gelişen sanayi, ve diğer iş sahalarında bulunan
                tehlikelerin yok edilmesi veya en aza indirilmesi ile
                çalışanların sağlığı ve güvenliğini korumak amacıyla bu konuda
                yetişmiş profesyonellere ihtiyaç duyulmaktadır. Kurumumuzdan
                alacağınız eğitim programını tamamladıktan sonra Çalışma ve
                Sosyal Güvenlik Bakanlığı tarafından yapılacak sınavda başarılı
                olarak İş Güvenliği Uzmanlığı Belgesi almaya hak kazanırsınız.
                <br />
                <br />
                <Button to="/is-guvenligi-uzmanligi-egitimleri">
                  Eğitim Detayları
                </Button>
              </TabPanel>
              <TabPanel>
                Eğiticinin Eğitimi Programı başarılı bir eğitmen olmak
                isteyenlere, bu hedef için gereken tüm bilgi ve becerileri
                kazandırmak amacına yönelik bir eğitim programıdır. Eğitim ve
                öğretim yöntemlerinin temel kavram ve ilkelerinin aktarıldığı
                programla; Eğitimcinin, etkili sunum tekniklerine vakıf,
                interaktif iletişim niteliklerinin geliştirilmesi
                amaçlanmaktadır.
                <br />
                <br />
                <Button to="/egiticinin-egitimi"> Eğitim Detayları</Button>
              </TabPanel>
              <TabPanel>
                MYK tarafından yetkilendirilmiş belgelendirme kuruluşlarınca,
                yetki kapsamlarında yer alan ulusal yeterlilik(ler)de
                tanımlanmış esaslara göre yürütülen ölçme değerlendirme
                faaliyetleri sonucunda başarılı olan bireyler için düzenlenen ve
                bireyin söz konusu ulusal yeterlilikte belirtilen öğrenme
                kazanımlarına (bilgi, beceri ve yetkinlik) sahip olduğunu
                gösteren belgelerdir.
                <br />
                <br />
                <Button to="/mesleki-yeterlilik-sinav-ve-ozel-egitimleri">
                  Eğitim Detayları
                </Button>
              </TabPanel>
              <TabPanel>
                İKÜSEM işbirliği ile düzenlenen Bu eğitim programında; yangına
                neden olan tehlikeler ve önlenmesi için alınacak tedbirler ile
                yangın sırasında can güvenliğinin sağlanması, tahliye ve
                müdahale basamakları ile söndürme teknikleri aktarılmaktadır. Bu
                bilgiler ışığında olası bir tehlike durumunda zamanında ve doğru
                müdahale ile katılımcıların hem kendilerinin hem de ortamda
                bulunan diğer kişilerin can güvenliğinin korunması
                amaçlanmıştır. Tehlikeli Madde Güvenlik Danışmanı (TMGD) olmak
                isteyenler TMGD Eğitimi başvuru ön şartı gereğince ‘’Uygulamalı
                Yangın Söndürme Eğitimi’’ almalıdırlar. Mevzuat uyarınca geçerli
                olacak biçimde düzenlenen Eğitimler T.C. İstanbul Kültür
                Üniversitesi İncirli yerleşkesinde organize edilmekte ve
                sertifikalandırılmaktadır.
                <br />
                <br />
                <Button to="/yanginla-mucadele-ve-yangin-sondurme-egitimi">
                  Eğitim Detayları
                </Button>
              </TabPanel>
              <TabPanel>
                Personel belgelendirmesi yapan kuruluşların ISO/IEC 17024
                standardına göre akredite edilmiş olması için gerekli sistemin
                kurulması ve dokümantasyonun oluşturulması konusunda danışmanlık
                hizmetleri kurumumuz tarafından sağlanmaktadır.
                <br />
                <br />
                <Button to="/iso-17024-dokumantasyonu-danismanlik">
                  Eğitim Detayları
                </Button>
              </TabPanel>
            </Tabs>
          </section>
        </Container>
      </Layout>
    </>
  )
}
