import React from "react"
import Container from "../container"
import Title from "../title"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Button from "../button"
import styles from "./hero.module.scss"

const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          sharp: childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.hero.sharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={styles.hero}
          fluid={imageData}
        >
          <Container className={styles.container}>
            <Title headingLevel="h2">İş Güvenliği Uzmanlığı Eğitimi </Title>
            <Title headingLevel="p">
              İstanbul Kültür Üniversitesi Sürekli Eğitim Merkezi ile birlikte
              geliştirdiğimiz projemizde akademik bilgi ve saha tecrübelerimizi
              birleştirerek üst düzey bir eğitim sunuyoruz.
            </Title>
            <Button to="/is-guvenligi-uzmanligi-egitimleri" type="secondary">
              Eğitim Detayları
            </Button>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

export default Hero
